@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
    margin-top: 0;
  }
  & input {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #9da3ae;
    padding-left: 0;
    &:focus {
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #9da3ae;
      box-shadow: none;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      padding-left: 0;
    }
  }
}
.inputWithImage {
  position: relative;
}
.text {
  /* Position */
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(-0px, -50%);
  z-index: 9;
  color: #8e8e8e;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.305px;
}

.title {
  color: var(--Black, #000);
  text-align: center;
  font-family: 'bon-vivant';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 325% */
  margin: 0;
  padding: 0 ;
  @media (--viewportMedium) {
    line-height: 67px; /* 325% */
  } 
}

.formWrapper {
  margin-top: 25px;
  @media (max-width: 767px) {
    margin-top: 10px;
  }
}

.Subtitle {
  color: var(--Standby-Grey, #4f4f4f);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  margin: 0;
  padding: 0;
  @media (max-width: 767px) {
    font-size: 16px;
  }

}

.signInText {
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0;
  padding: 0;
  @media (max-width: 767px) {
    font-size: 16px;
  }

}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 18px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin-top: 0;
  text-align: left;
  & span {
    color: var(--Standby-Grey, #4f4f4f);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 975% */
    text-decoration-line: underline;
    @media (max-width: 767px) {
      font-size: 14px;

    }
  }
}

.loginBtn {
  background: var(--Black, #000);
  &:hover {
    background: var(--Black, #222222);
  }
  @media (max-width: 767px) {
    font-size: 16px;
    font-weight: 500;
    border-radius: 50px;
  } 
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.formloginLInk {
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 975% */
  margin: 17px 0 0;
  padding: 0;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  & a {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-decoration-line: underline;
  }
}

.heighlightText {
  font-weight: 500;
  cursor: pointer;
}