@import '../../styles/customMediaQueries.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;
  background: #b06b5d;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh;
}

.content {
  max-width: 587px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (--viewportMedium) {
    margin-top: 14vh;
  }
}

.number {
  color: #fff;
  font-family: 'bon-vivant';
  font-size: 200px;
  font-style: normal;
  font-weight: 400;
  line-height: 170px;
  text-align: center;
  @media (--viewportMedium) {
    margin: 0;
  }
}

.heading {
  color: #fff;
  font-family: Montserrat;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  @media (--viewportMedium) {
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

.searchForm {
  max-width: 408px;
  margin: 62px auto 0 auto;

  background-color: var(--colorWhite);
  border-bottom: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (--viewportMedium) {
    margin-top: 64px;
  }
}
