@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
    margin-top: 0;
  }
  & label {
    display: flex;
    align-items: center;
    & > span:first-child {
      height: 32px;
    }
  }
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 0px 0 0px 0;
  text-align: left;
  color: var(--Standby-Grey, #4f4f4f) !important;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 1114.286% */
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--marketplaceColor);
  color: var(--Standby-Grey, #4f4f4f);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-decoration-line: underline;
  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: underline;
    cursor: pointer;
  }
}
