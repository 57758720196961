@import '../../styles/customMediaQueries.css';

.link {
  & .imageWrapper {
    height: 232px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    position: relative;
    @media (--viewportMedium) {
      height: 330px;
    }
    & .onproduct {
      position: absolute;
      top: 10px;
      right: 12px;
      margin: 0;
      padding: 4px 10px;
      font-size: 16px;
      color: #fff;
      background:  #b06b5d;
      border-radius: 45px;
    }
  }
}

.root {
  position: relative;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0rem .75rem .5rem;
  @media (--viewportMedium) {
    padding: 6px 12px 12px;
  }
 
}

.emptySpace{
  flex: 1;
}

.likeIcon {
  border-radius: 9999px;
  background: var(--Sand---Primary, #efe4d3);
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
}

.priceWrapper {
  & p {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0;
    @media (--viewportLarge) {
      font-size: 14px;
    }
  }
  & span {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.bagButton {
  /* width: 73.09px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Sand---Primary, #efe4d3);
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  height: 26px;
  @media (--viewportLarge) {
    height: 33px;
  }
  & > span {
    padding: 2px 9.7px 2px 10px;
    @media (--viewportLarge) {
      padding: 4px 9.7px 4px 10px;
    }
    cursor: pointer;
    gap: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.title {
  color: #1f2937;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0;
  padding: .5rem .75rem;
  @media (--viewportMedium) {
    font-size: 14px;
    padding: 9px 12px 3px;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid;
  border-color: #fff #fff;
  animation: l1 1s infinite;
}
@keyframes l1 {to{transform: rotate(.5turn)}}


.modalRemove{
  & > div{
    & > div{
      flex-basis: 448px !important;
      & > button{
        display: none !important; 
      }
    }
  }
}

.deleteIcon{
  height: 72px;
  width: 72px;
  fill: #000;
}

.modalContent{
  text-align: center;
  
  & .modalTitle{
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    line-height: 1.75rem;
    font-size: 1.25rem;
    font-weight: 700;
  }
  & .modalText{
    font-size: .875rem;
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
  }

  & .buttonGroups{
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-top: 40px;
  }
  & .cancelBtn{
    width: 8rem;
    min-height: 40px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    border-radius: 50px;
    font-weight: 400;
  }
  & .removeBtn{
    width: 8rem;
    min-height: 40px;
    border-radius: 50px;
    font-weight: 400;
  }
}

.desktopText{
  @media (max-width:767px) {
    display: none;
  }
}