@import '../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;
  & .container {
    transform: scale(1);
    animation: unfoldIn 0.2s ease-in;
  }
  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--colorWhite);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
    @media (max-width: 767px) {
      min-height: auto;
      background-color: transparent;
      flex-grow: unset;
      max-width: calc(100vw - 60px);
      width: 100%;
    }
  }

  & .container {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--colorWhite);
    border-radius: var(--borderRadius);
    border-bottom: none;

    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      flex-basis: 634px;
      flex-grow: 0;
      min-height: auto;
      height: auto;

      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.1), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
      /* border-bottom: 8px solid var(--marketplaceColor); */
    }
    @media (max-width: 767px) {
      min-height: auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 0.75rem;
      padding-top: 0.5rem;
      max-width: calc(100vw - 60px);
      border-radius: 4px;
      max-height: 80vh;
      overflow-y: auto;
      overflow-x: hidden;
      & > button {
        padding: 10px !important;
        & svg {
          width: 18px;
          height: 18px;
          margin-left: 0;
        }
      }
    }
  }
}

.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media (max-width: 767px) {
    background-color: rgb(17 24 39 / 0.3) !important;
  }
}

.isOpenInPortal {
  composes: isOpen;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media (max-width: 767px) {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(17 24 39 / 0.3) !important;
  }
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }
}

.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
  & .container {
    transform: scale(0);
    animation: unfoldOut 1s ease-in;
  }
}

.close {
  composes: marketplaceModalCloseStyles from global;
  @media (max-width: 767px) {
    position: sticky;
    justify-content: flex-end;
  }
  &.modalHeader {
    @media (max-width: 767px) {
      top: -7px;
      position: sticky;
      justify-content: flex-start;
      border-bottom: 1px solid #d1d5db;
      background-color: #fff;
      margin-top: -10px;
    }
    & .title{
      display: none;
      font-weight: 400;
      flex: 1;
      text-align: center;
      color: rgb(55 65 81 / 1);
      @media (max-width: 767px) {
       display: block;
      }
    }
  }
}

@keyframes unfoldIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.4);
  }
  100% {
    transform: scale(0);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.closeLight {
  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorWhite);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}