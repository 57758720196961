.onBoardingBtn {
  margin-top: 40px;
  max-width: 40%;
}

.box {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding:10px 20px;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.externalLink{
    margin-top: 40px;
    display: block;
}

.dotsPayoutPagePanel{
  
}