@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
    margin-top: 0;
  }

  & input {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #9da3ae;

    @media (min-width: 768px) {
      padding: 4px 16px 4px 0px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      padding-left: 0;
    }

    &:focus {
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #9da3ae;
      box-shadow: none;
    }
  }
}

.errorText {
  color: red;
}

.inputWithImage {
  position: relative;
}

.text {
  /* Position */
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(-0px, -50%);
  z-index: 9;
  color: #8e8e8e;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.305px;
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-top: 19px;
  }
}

.firstNameRoot {
  width: calc(49% - 9px);
}

.lastNameRoot {
  width: calc(49% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 32px;

  @media (max-width: 767px) {
    margin-top: 38px;
  }
}

.title {
  color: var(--Black, #000);
  text-align: center;
  font-family: 'bon-vivant';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 67px;
  /* 325% */
  margin: 0;

  @media (max-width: 767px) {
    padding: 0;
    line-height: 60px;
  }
}

.agreeSignUp {
  margin: 0 0 15px;
  padding: 0;
  text-align: left;

  & * {
    color: var(--Standby-Grey, #4f4f4f);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  & a {
    text-decoration: underline;
  }
}

.formWrapper {
  margin-top: 25px;

  @media (max-width: 767px) {
    margin-top: 15px;
  }
}

.fieldDateInput {
  & input::placeholder {
    font-size: 14px;
    color: var(--colorGrey300);
    font-weight: 400;
    @media (--viewportMedium) {
      font-size: 16px;
    }
  }

  & input {
    border-radius: 0 !important;

    @media (max-width: 767px) {
      font-size: 14px !important;
    }
  }

  & label {
    font-size: 14px;
  }
}

.Subtitle {
  color: var(--Standby-Grey, #4f4f4f);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 177.778% */
  margin: 0;
  padding: 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.signInText {
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  margin: 0;
  padding: 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.heighlightText {
  font-weight: 500;
  cursor: pointer;
}

.formloginLInk {
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 975% */
  margin: 17px 0 0;
  padding: 0;

  & a {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-decoration-line: underline;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    margin-top: 6px;
  }
}

.loginBtn {
  background: var(--Black, #000);
  border-radius: 50px;

  &:hover {
    background: var(--Black, #222222);
  }

  @media (max-width: 767px) {
    font-size: 16px;
    font-weight: 500;
  }
}

:global(.rmdp-today) span {
  background-color: transparent !important;
  color: #000 !important;
}

:global(.rmdp-week-day) {
  color: #000 !important;
}

:global(.rmdp-selected) span {
  background-color: #000 !important;
  color: #fff !important;
}

:global(.rmdp-day):hover span {
  background-color: #b06b5dac !important;
}

:global(.rmdp-arrow) {
  border-color: #000 !important;
  margin: 0 !important;
}

:global(.rmdp-arrow-container) {
  align-items: center;
  justify-content: center;
}

:global(.rmdp-arrow-container):hover {
  background-color: transparent;
}

:global(.ep-arrow) {
  display: none !important;
}

.dropDownDate {
  &>*:last-child {
    transform: translate(5px, 51.5px) !important;
  }
}

.dateWrapper {
  position: relative;
  margin-top: 19px;

  @media (max-width: 767px) {
    &>div {
      & :global(.SingleDatePicker_picker) {
        top: 100% !important;
        left: -10px !important;
      }
    }
  }

  &>div {
    width: 100%;
  }

  & .placeHolder {
    display: block;

    font-size: 0.875rem;
    line-height: 1.25rem;
    z-index: 2;
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 975% */
  }

  & label {
    font-size: 0.875rem;
    line-height: 1.25rem;
    z-index: 2;
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 975% */
    padding: 0;
    margin-bottom: -9px;
    position: relative;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  & input {
    box-sizing: border-box;
    outline: 0;
    position: relative;
    width: 100%;
  }

  & input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 10;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}