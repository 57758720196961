.root {
  height: 100%;
  width: 100%;
  border: 0;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

.clickedWithMouse {
  &:focus {
    outline: none;
  }
}

/* Default isOpen class - no styles yet */
.isOpen {
}

:global(.line) {
  stroke-width: 8;
  stroke-linecap: round;
  stroke: #000;
  transition: all 500ms;
}

:global(.top) {
  transform-origin: 26px 40px;
}

:global(.middle) {
  stroke-dasharray: 60 60;
}

:global(.bottom) {
  transform-origin: 26px 60px;
}

.isOpen {
  :global(.top) {
    transform: rotate(45deg);
  }
  :global(.middle) {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
  }
  :global(.bottom) {
    transform: rotate(-45deg);
  }
}
