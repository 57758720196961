@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 20px;
  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
  & > div {
    width: 100%;
    margin-top: 0px;

    @media (--viewportSmall) {
      width: calc(50% - 12px);
    }
  }
}
.locationBox {
  border: 1px solid #2e2825 !important;
  border-radius: 6px;
  margin-bottom: 1rem;
  & label {
    font-weight: 600;
    color: rgb(46 40 37 / 1);
    margin-bottom: 0.5rem;
    font-size: inherit;
    padding: 0;
  }
  & > div {
    border-bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-radius: 6px;
    &:before {
      position: relative;
      content: '';
      width: 18px;
      height: 18px;
      min-width: 18px;
      background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 512 512' class='text-lg text-gray-400' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z'%3E%3C/path%3E%3Ccircle cx='256' cy='192' r='48' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/circle%3E%3C/svg%3E");
    }
    & svg {
      display: none;
    }
  }
  & input {
    height: 36px !important;
    border: 0 !important;
    &:focus {
      border: 0 !important;
      box-shadow: none;
      outline: none;
    }
  }
}
.field {
  width: 100%;
  margin-top: 0px;
  & input {
    height: 36px !important;
    border: 0 !important;
    border: 1px solid #2e2825 !important;
    border-radius: 6px;

  }
  & select {
    height: 36px !important;
    line-height: inherit;
    font-size: 14px;
    border: 1px solid #2e2825 !important;
    border-radius: 6px;
   
  }
  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}
