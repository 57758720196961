@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: auto;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;

    background-color: var(--colorWhite);
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0 24px;

  display: none;
  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  /* composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  } */
  color: #374151;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  margin: 0;
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.contactBtn {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  & button {
    color: #374151;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
  }
}

.orginalPrice {
  color: #374151;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  @media (min-width: 1920px) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  @media (max-width: 1023px) {
    display: none;
  }
}

.author {
  composes: p from global;
  margin: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  @media (--viewportLarge) {
    width: 100%;
    margin: 12px 0 13px 0;
    color: var(--colorGrey700);
  }
  & .contactBtn {
    @media (max-width: 576px) {
      width: 100%;
      flex: auto;
      justify-content: flex-start;
    }
  }
}

.buttonGroups {
  display: flex;
  gap: 12px;
  margin-top: 28px;
}

.authorDetails {
  color: #374151;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  word-break: break-word;
  @media (max-width: 576px) {
    margin-top: 10px;
  }
}

.providerAvatar {
  /* display: none; */

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
    border-radius: 0;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  /* display: none; */

  font-size: 14px;
  font-weight: 600;
  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  /* display: none; */

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  /* display: none; */

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceContainer {
  color: #374151;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  @media (max-width: 1023px) {
    display: none;
  }
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
  margin-bottom: 18px;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.tabs {
  margin-top: 40px;
  border-bottom: 2px solid #d1d5db;
  padding-bottom: 11px;
  display: flex;
  gap: 23px;
  @media (max-width: 1023px) {
    margin-top: 1.25rem;
  }
  & span {
    color: #2e2825;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    position: relative;
  }
  & .active {
    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: -12px;
      height: 2px;
      background-color: #000;
    }
  }
}

.label {
  display: flex;
  gap: 5px;
  margin-top: 14px;
  font-size: 14px;
  &.itemType {
    flex-wrap: wrap;
  }
}

.modalMobile {
  display: block;
}
