@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  border-bottom: 1px solid #d1d5db;
  justify-content: flex-start !important;
  overflow-y: hidden;
  @media (min-width: 1024px) {
    padding: 0 1.25rem 0 0 !important;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}

.darkSkin {
  background-color: #fff;
  max-width: 1218px;
  margin: 0 auto;
  width: 100%;
  @media (min-width: 1920px) {
    max-width: 1324px;
    width: 100%;
  }
  @media (max-width: 1919px) {
    max-width: 1218px;
    width: 100%;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.tabContent {
  composes: a from global;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  white-space: pre;
  /* Font */
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: var(--colorGrey700);
  padding-bottom: 10px;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (--viewportMedium) {
    border-bottom-width: 2px;
    padding: 16px 10px 14px 10px;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  border-bottom-color: var(--colorBlack);
  color: var(--colorBlack);
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: #2e2825;
  font-size: 1rem;
  line-height: 1.5rem;
  position: relative;
  &:hover {
    color: #2e2825;
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--colorWhite);
  color: #2e2825;
  &:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 30px;
    background-color: #000;
    left: 0;
    right: 0;
    top: 100%;
    margin: 0 auto;
  }
}

.disabledDarkSkin {
  color: var(--colorBlack);
  opacity: 0.3;
}

.sliderWrapper {
  max-width: 430px;
  width: 100%;
  @media (max-width: 450px) {
    max-width: calc(100vw - 48px);
  }
  & :global(.slick-list) {
    padding: 0 20px;
  }
  & :global(.slick-track) {
    display: flex !important;
  }
  & :global(.slick-prev) {
    left: 0px !important;
    &:before {
      content: '' !important;
      background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'%3E%3C/path%3E%3C/svg%3E");
      width: 18px;
      height: 18px;
      display: flex;
      background-position: center;
    }
  }
  & :global(.slick-next) {
    right: 0px !important;
    &:before {
      content: '' !important;
      background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'%3E%3C/path%3E%3C/svg%3E");
      width: 18px;
      height: 18px;
      display: flex;
      background-position: center;
    }
  }
}
