@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  padding: 0 !important;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}
.modalRemove{
  & > div{
    & > div{
      flex-basis: 448px !important;
      & > button{
        display: none !important; 
      }
    }
  }
}

.deleteIcon{
  height: 72px;
  width: 72px;
  fill: #000;
}

.listingPageRatio{
  min-height: 236px;
  overflow: hidden;
  & > div{
    & > div{
      & > div{
    height: 100%;
    & > div{
      height: 100%;
        }
      }
    }
  }
}

.listingPageMain{
  & .cardFooter{
    padding-top: 0;
  }
}

.modalContent{
  text-align: center;
  
  & .modalTitle{
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    line-height: 1.75rem;
    font-size: 1.25rem;
    font-weight: 700;
  }
  & .modalText{
    font-size: .875rem;
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
  }

  & .buttonGroups{
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-top: 40px;
  }
  & .cancelBtn{
    width: 8rem;
    min-height: 40px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    border-radius: 50px;
    font-weight: 400;
  }
  & .removeBtn{
    width: 8rem;
    min-height: 40px;
    border-radius: 50px;
    font-weight: 400;
  }
}
.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: 0;
  min-height: 224px;
  overflow: hidden;
  @media (--viewportMedium) {
    min-height: 340px;
    /* &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    } */
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 0px 0 0px 0;
}

.priceWrapper {
  & p {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0;
    @media (--viewportLarge) {
      font-size: 14px;
    }
  }
  & span {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.bagButton {
  /* width: 73.09px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Sand---Primary, #efe4d3);
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  height: 26px;
  @media (--viewportLarge) {
    height: 33px;
  }
  & > span {
    padding: 2px 9.7px 2px 10px;
    @media (--viewportLarge) {
      padding: 4px 9.7px 4px 10px;
    }
    cursor: pointer;
    gap: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.desktopTextDisable {
  /* width: 73.09px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Standby-Light, #9DA3AE);
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  height: 26px;
  @media (--viewportLarge) {
    height: 33px;
  }
  & > span {
    padding: 2px 9.7px 2px 10px;
    @media (--viewportLarge) {
      padding: 4px 9.7px 4px 10px;
    }
    cursor:not-allowed;
    gap: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.price {
  /* Layout */
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px; */
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  color: #1f2937;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0;
  padding: .5rem .75rem;
  @media (--viewportMedium) {
    font-size: 14px;
    padding: 9px 12px;
  }
}

.cardFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
 
  padding: 0rem .75rem .5rem;
  @media (--viewportMedium) {
    padding: 12px;
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.desktopText{
  @media (max-width:767px) {
    display: none;
  }
}
.imageWrapper{
  position: relative;
  & .soldText{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 2;
    background-color: #302E2F;
    font-size: 18px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}