.fieldBtn {
  margin-left: auto;
  margin-top: 1rem;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: max-content;
  min-height: auto;
  &.fieldBtnLeft{
    margin-left: 0;
  }
  &.fieldBtnCenter{
    margin-left: 32%;
    display: flex;
    width: 231.449px;
    height: 54px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 25px;
  }
}

.iconClose {
  margin-left: auto;
  width: max-content;
  cursor: pointer;
}

.selected {
  width: 50%;
  height: 50%;
  border-radius: 5px; /* Use 'px', 'em', or another unit for border-radius */
  background-color: black;
}

.savedCard {
  display: flex;
  gap: 8px;
  margin-top: 24px;
  align-items: center;
}

.savedCardEnd{
  display: flex;
  gap: 8px;
  margin-top: 0px;
  align-items: flex-end;
  & .customChecks{
    margin-bottom: 5px;
  }
}

.customChecks {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #000;
  min-width: 16px;
}

.customChecked{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #000;
  min-width: 16px;
  background-color: #000; 
  margin-bottom: 4px;
}

.textSm{
  font-size: 14px;
}

.checkbox{
  display: flex;
  gap: 10px;
}

.formRoot{
  margin-top: 23px;
  max-width: 545px;
}

.applyRemaining {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 28px;
}

.radioBoxes {
  display: flex;
  gap: 19px;
}

.radioBox {
  display: flex;
  align-items: center;
  gap: 5px;
}
